import { Fragment } from "react"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { useFetchHomeMembers } from "src/data/organizations/queries/homeQueries"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { InternalLink } from "src/ui/Link/InternalLink"
import { formatDate } from "src/utils/l10n"

export function ParadiseHomeMembers({
  orgId,
  homeId,
}: {
  homeId: string
  orgId: string
}) {
  const { navigate } = useRouter()

  const fetchHomeMembers = useFetchHomeMembers({
    orgId,
    homeId,
  })

  const members = fetchHomeMembers.data?.members

  const header = [
    <div key={"user-id"}>User id</div>,
    <div key={"email"}>Email</div>,
    <div key={"role"}>Role</div>,
    <div key={"joined-at"}>Joined at</div>,
  ]

  const rows = members?.map((member) => (
    <Fragment key={member.member_id}>
      <div>
        {member.user_id ? (
          <InternalLink
            to={Routes.ParadiseUser.location(member.user_id)}
            onClick={(e) => e.stopPropagation()}
          >
            {member.user_id}
          </InternalLink>
        ) : (
          "-"
        )}
      </div>
      <div>{member.email}</div>
      <div>{member.role}</div>
      {member.joined_at && (
        <div>{formatDate({ date: member.joined_at, timezone: "utc" })}</div>
      )}
    </Fragment>
  ))

  return (
    <div>
      <ParadiseDetailsSection title="Members" />
      <ParadiseTable
        header={header}
        rows={rows ?? []}
        onRowClick={(index) => {
          // @ts-expect-error: noUncheckedIndexedAccess
          const memberId = members?.[index].user_id

          if (memberId) {
            navigate(Routes.ParadiseUser.location(memberId))
          }
        }}
        error={{
          hasError: fetchHomeMembers.isError,
          title: fetchHomeMembers.error?.message,
        }}
      />
    </div>
  )
}
